import React from "react";
import {
  CashIcon,
  SaveAsIcon,
  GiftIcon,
  CubeTransparentIcon,
  LightBulbIcon,
  PhotographIcon,
} from "@heroicons/react/outline";
// @ts-ignore
import Bounce from "react-reveal/Bounce";
import { PieChart } from "react-minimal-pie-chart";
// @ts-ignore
import Pulse from "react-reveal/Pulse";
import { SectionTitle } from "./common/SectionTitle";
import { activeYear } from "../constants";

const features = [
  {
    name: "Total token supply",
    description: "1,000,000,000",
  },
  {
    name: "Initial Circulating Supply",
    description: "150,000,000",
  },
  {
    name: "Presale price",
    description: "$0.10",
  },
  // {
  //   name: "Address (ERC-20)",
  //   description: "0x2b3360320f0dffe3bf71b054fd0a7460d0129c51",
  // },
];

const economy = [
  {
    name: "Governance",
    description:
      " Decisions are made via proposals the votes on a specified period",
    icon: CashIcon,
  },
  {
    name: "Staking",
    description: "Staking $MAX on Maxima DeFi platform and other DeFi pools",
    icon: SaveAsIcon,
  },
  {
    name: "Rewards",
    description:
      "Rewarded with bonus in accordance with the participation rate",
    icon: GiftIcon,
  },
  {
    name: "Profit Sharing",
    description: "Profit sharing to users from race-horsing events",
    icon: CubeTransparentIcon,
  },
  {
    name: "Community",
    description:
      "Enable social interaction and content sharing by supporting the community",
    icon: LightBulbIcon,
  },
  {
    name: "Exclusive Offers",
    description:
      "Special campaign offer within a limited period and club offering",
    icon: PhotographIcon,
  },
];

export default function Token() {
  const distributionElement = (
    <div className="bg-green-900">
      <div className="max-w-2xl px-4 pt-16 pb-8 mx-auto lg:max-w-7xl">
        <div className="grid items-center grid-cols-1 lg:grid-cols-5">
          <div className="col-span-2 text-center lg:text-left">
            <h3 className="text-4xl font-extrabold text-transparent lg:text-5xl xl:text-6xl bg-clip-text bg-gradient-to-br from-green-100 to-maxima-secondary-400">
              The $MAX Token
            </h3>

            <dl className="mt-10 space-y-10">
              {features.map((feature) => (
                <Bounce key={feature.name}>
                  <div>
                    <dt className="text-2xl font-medium text-gray-200">
                      {feature.name}
                    </dt>
                    <dd className="mt-3 text-4xl antialiased font-extrabold text-gray-300 md:text-6xl">
                      {feature.description}{" "}
                    </dd>
                  </div>
                </Bounce>
              ))}
            </dl>
          </div>

          <Pulse>
            <div className="flex justify-end col-span-3">
              <div className="w-full">
                <PieChart
                  segmentsShift={(index) => (index === 4 ? 2 : 0)}
                  lineWidth={65}
                  data={data}
                  label={({ x, y, dx, dy, dataEntry }) => (
                    <text
                      x={x}
                      y={y}
                      dx={dx}
                      dy={dy}
                      dominantBaseline="central"
                      textAnchor="middle"
                      style={{
                        fontSize: "2px",
                        fill: "white",
                      }}
                    >
                      {dataEntry.title}
                    </text>
                  )}
                  labelStyle={{
                    ...defaultLabelStyle,
                  }}
                  labelPosition={160}
                  animate
                  radius={PieChart.defaultProps.radius - 30}
                />
              </div>
            </div>
          </Pulse>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <section className="anchor-token">
        {false && distributionElement}
        <div className="relative py-16 sm:py-24 lg:py-28">
          <div className="max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
            <SectionTitle
              subText={`Available in Q4 ${activeYear}`}
              isSubTextAbove
            >
              The $MAX DAO Token
            </SectionTitle>

            <div className="mt-12">
              <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                {economy.map((feature) => (
                  <div
                    key={feature.name}
                    className="pt-12 bg-gradient-to-br from-maxima-secondary-400 to-gray-900 rounded-xl"
                  >
                    <Bounce>
                      <div className="flow-root px-6 pb-8 rounded-lg">
                        <div className="-mt-6">
                          <div>
                            <span className="inline-flex items-center justify-center p-6 rounded-md">
                              <feature.icon
                                className="w-12 h-12 text-white"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                          <h3 className="text-4xl font-bold tracking-tight text-maxima-secondary-100">
                            {feature.name}
                          </h3>
                          <p className="mt-5 text-xl antialiased font-semibold text-gray-200">
                            {feature.description}
                          </p>
                        </div>
                      </div>
                    </Bounce>
                  </div>
                ))}
              </div>
            </div>

            <div className="flex items-center justify-center mt-12">
              {/* <div className="inline-flex rounded-md shadow">
                <button
                  onClick={() => setCurrentDialog(DialogName.Contact)}
                  className="inline-flex items-center justify-center px-5 py-3 text-xl text-gray-400 rounded-md hover:text-maxima-secondary-200"
                >
                  Get $MAX Whitelisted
                  <span className="ml-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-7 h-7"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                    </svg>
                  </span>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const defaultLabelStyle = {
  fontSize: "3px",
  fill: "white",
};

const data = [
  {
    title: "Public Presale (5%)",
    value: 5,
    color: "#ECFDF5",
  },
  { title: "IDO (10%)", value: 10, color: "#D1FAE5" },
  {
    title: "Advisors and Partners (10%)",
    value: 10,
    color: "#A7F3D0",
  },
  { title: "Team (15%)", value: 15, color: "#6EE7B7" },
  {
    title: "Private Sales (15%)",
    value: 15,
    color: "#34D399",
  },
  {
    title: "Reserves & Rewards (45%)",
    value: 25,
    color: "#059669",
  },
];
